<template>
  <div style="color: #666;font-size: 14px;">

      <el-row :gutter="20">
        <el-col :span="12">
          <el-card>
            <div slot="header" class="clearfix" style="display: flex; flex-direction: row;justify-content: space-between">
              <div style="font-size: 20px; font-weight: bold">学习进度</div>
              <el-button v-if="user.role==='SYS_ADMIN'" type="primary" icon="el-icon-plus" @click="handleAdd">新增进度</el-button>
            </div>
            <div class="block" style="height: calc(100vh - 470px); overflow-y: auto;">
              <el-timeline>
                <el-timeline-item  v-for="item in notebooks" :key="item.id" :timestamp="item.time" placement="top" style=" width: 90%">
                  <div style="display: flex; flex-direction: row;align-items: center">
                    <div style="flex: 8">
                      <el-card :body-style="{ padding: '10px' }">
                        <el-popover
                            :disabled="item.content===''"
                            placement="bottom-start"
                            width="500"
                            trigger="hover"
                            :content="item.content">
                          <span slot="reference">{{ item.title }}</span>
                        </el-popover>
                      </el-card>
                    </div>
                    <div v-show="item.articleId" style="flex: 2; padding-left:20px">
                      <el-button v-if="user.role==='SYS_ADMIN'" type="primary" @click="view(item.articleId)">查看文章</el-button>
                    </div>

                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-card>
        </el-col>
<!--        使用说明-->
        <el-col :span="12">
          <div style="background: #1E90FF;height: calc(100vh - 420px);">
            <el-card class="box-card">
              <div slot="header" class="clearfix" style="font-size: 20px; font-weight: bold;text-align: center">
                <span>使用说明</span>
<!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
              </div>
              <div  style="height: calc(100vh - 470px); overflow-y: auto;">
<!--                <p>SpringBoot+Vue前后端分离项目练习，是我在2023年12月初深入学习Vue时，就打算搭建一个网站，记录学习历程，记录下学习中遇到的-->
<!--                  各种难题，学习心得，并记录下小成果。</p>-->
<!--                <p>项目分为几部分：springboot负责后端，提供Vue，小程序，公众号访问接口，Vue负责后台管理和网站前台页面，小程序和公众号负责-->
<!--                  手机端应用。</p>-->
<!--                <p>一、后台管理，是项目的核心部分，</p>-->
                <p></p>
                <p></p>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>

    <!--  网页的footer信息，高200px  -->
    <div style="height: 200px;position: fixed;top:calc(100vh - 222px);width: 84%; ">
      <div style="height: 3px; background: #ddd; margin: 3px 0"/>
      <div style="padding: 10px 0; font-size: 20px">开发环境：IDEA，项目管理：Maven，后端语言：java，前端语言：Vue</div>
      <div style="display: flex;flex-direction: row; align-items: center;">
        <div style="padding: 10px 0; font-size: 20px; width: 80px;">技术栈:</div>
        <div style="display: flex;flex-direction: column;">
          <div>
            <b style="padding-right: 10px">后端</b>
            <span>SpringBoot、MySQL、Mybatis-plus、Lombok、Swagger、JWT、Hutool、Poi、generator等</span>
          </div>
          <div>
            <b style="padding-right: 10px">前端</b>
            <span>Vue2、Vue-Router、VueX、ElementUI、Axios、Vue-Baidu-Map、Echarts、QRCode、bmaplib.distancetool等</span>
          </div>
        </div>
      </div>
      <div style="height: 1px; background: #ddd; margin: 3px 0"/>
      <div style="display: flex;flex-direction: row;">
        <div style="display: flex;flex-direction: column; justify-content: space-around; width: 500px;">
          <b>链  接</b>
          <div style="font-size: 12px"><span style="padding-top: 5px">在线考试：</span>
            <el-link type="primary" href="https://www.yz-exam.cn:12345" target="_blank">https://www.yz-exam.cn:12345</el-link>
          </div>
          <div style="font-size: 12px"><span>项目练习：</span>
            <el-link type="primary" href="http://www.yz-awen.cn:8081" target="_blank">http://www.yz-awen.cn:8081</el-link>
          </div>
        </div>
        <div style="display: flex;flex-direction: column; justify-content: space-around; width: 369px;">
<!--          <b style="padding-bottom: 5px">微信小程序</b>-->
          <div style="display: flex;flex-direction: row;">
            <div style="display: flex;flex-direction: column;width: 50%">
              <el-image :src="require('@/assets/在线考试.jpg')" style="width: 80px; height: 80px"></el-image>
              <div style="font-size: 12px">在线考试小程序</div>
            </div>
            <div style="display: flex;flex-direction: column;width: 50%">
              <el-image :src="require('@/assets/环境监控.jpg')" style="width: 80px; height: 80px"></el-image>
              <div style="font-size: 12px">项目练习小程序</div>
            </div>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;width: 160px;">
          <el-image :src="require('@/assets/星球-小.png')" style="width: 80px; height: 80px"></el-image>
          <div style="font-size: 12px">微信公众号</div>
        </div>
        <div style="display: flex;flex-direction: column;width:100%;align-items: center;justify-content: space-around;">
          <div>© 2024 yz_awen ®</div>
          <el-link type="primary" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">桂ICP备2023000767号-1</el-link>
          <div style="display: flex;flex-direction: row">
            <el-image :src="require('@/assets/安备.png')" style="width: 20px; height: 20px"></el-image>
            <el-link type="primary" href="https://beian.mps.gov.cn/#/query/webSearch?code=45128102451368" target="_blank">桂公网安备45128102451368号</el-link>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="新增进度" :visible.sync="fromVisible" width="200" :close-on-click-modal="false" destroy-on-close>
      <el-form :model="form" label-width="100px" style="padding-right: 50px" ref="formRef">
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="文章ID" v-show="false">
          <el-input v-model="form.articleId"></el-input>
        </el-form-item>
        <el-form-item label="发布日期" v-show="false">
          <el-input v-model="form.time"></el-input>
        </el-form-item>
        <el-form-item label="关联文章" prop="testresults">
          <el-autocomplete
              @blur="inputBlur"
              value-key="value"
              class="inline-input"
              v-model="form.articleName"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fromVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="文章信息" :visible.sync="viewDialogVis" width="60%" top="3vh">
<!--      <el-card>-->
        <div style="height: calc(100vh - 200px); overflow-y: auto;border: 1px solid #666666">
          <mavon-editor
              class="md"
              :value="article.content"
              :subfield="false"
              :defaultOpen="'preview'"
              :toolbarsFlag="false"
              :editable="false"
              :scrollStyle="true"
              :ishljs="true"
          />
        </div>
<!--      </el-card>-->
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Header from "@/components/Header";
export default {
  name: "Home",
  components: {Header},
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      notebooks: [],
      fromVisible: false,
      form: {},
      articleList: [],
      viewDialogVis: false,
      article:{},
    }
  },
  created() {
    this.request.get('/notebook/selectAll').then(res => {
      this.notebooks = res.data || []
    })
  },
  methods:{
    handleAdd() {   // 新增数据
      this.form = {title:'',content:'',articleName: '', articleId: ''}  // 新增数据的时候清空数据
      this.fromVisible = true   // 打开弹窗
      // 获取文章列表
      this.request.get("/article").then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.articleList=[]
          res.data.forEach(item=>{
            this.articleList.push({ articleId: item.id, value: item.name })
          })
          console.log(this.articleList)
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    save() {   // 保存按钮触发的逻辑  它会触发新增或者更新
      this.form.time = moment().format("YYYY-MM-DD"); // 获取当前日期并格式化
      this.form.user = this.user.nickname
      this.request.post("/notebook", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.fromVisible = false
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.articleList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item.articleId);
      this.form.articleId=item.articleId
    },
    inputBlur(){
      var flag = false
      this.articleList.forEach(item=>{
        if(this.form.articleName === item.value){
          flag = true
          this.form.articleId = item.articleId
        }
      })
      if(!flag){
        this.form.articleName = ''
        this.form.articleId = ''
      }
    },
    view(articleId){
      this.request.get("/article/" + articleId).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          console.log(res.data)
          this.article = res.data
          this.viewDialogVis = 'true'
        } else {
          this.$message.error("删除失败")
        }
      })
    },


  },
}
</script>
<style scoped>
::v-deep .el-card__header {
  margin: 0px;
  padding: 10px;
  /*height: 24px;*/
  background: #8c939d;
}

</style>
